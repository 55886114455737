import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
export default {
  components: {},
  address: -1,
  data: () => ({
    search: '',
    headers: [{
      text: 'Kunde',
      align: 'start',
      sortable: false,
      value: 'address'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }]
  }),
  mounted() {},
  computed: {
    ...mapState({
      salesPerAdressForSM: state => state.articleStatisticsDataTable.DataPerAdressForSM
    })
  },
  methods: {
    openAdresses(value) {
      this.$router.push(`/crm/addresses/${value.addressId}`);
    },
    opencolor(value) {
      if (this.address == value.address) {
        this.address = '';
      } else {
        this.address = value.address;
      }
    },
    itemRowBackground2: function (item) {
      if (item.address == this.address) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    }
  }
};