import "core-js/modules/es.array.push.js";
import ArticleDetails from '@/components/article/ArticleDetails.vue';
import ArticleStatistics from '@/components/article_statistics/ArticleStatistics.vue';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import { mapState } from 'vuex';
export default {
  components: {
    ArticleStatistics,
    ArticleDetails,
    SiteHeaderComponent
  },
  data() {
    return {
      tab: 0,
      tabs: ['Allgemein', 'Statistik']
    };
  },
  methods: {
    init() {
      if (this.$route.query.tab == 'Statistik') {
        this.tab = 1;
      } else {
        this.$router.replace({
          query: {
            tab: this.tabs[0]
          }
        });
      }
      this.loadBasicInformation();
    },
    navigateToArticleSearch() {
      this.$router.push({
        name: 'Artikel'
      });
    },
    loadBasicInformation() {
      try {
        this.$store.dispatch('GET_BASE_INFORMATION', this.$route.params.articleNumber);
      } catch (err) {
        if (this.$store.state.articleDetails.articleExists) {
          this.failMessage = 'Beim Laden der Basisinformationen ist ein Fehler aufgetreten';
          this.errorDialog = true;
          if (err.response.status === 404) {
            console.log('No Base Informations found');
          } else {
            console.error(err);
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      baseInformation: state => state.articleDetails.baseInformation,
      articleExists: state => state.articleDetails.articleExists
    }),
    siteHeader() {
      return `${this.baseInformation.articleDescription}`;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.params': 'init',
    tab(newVal) {
      this.$router.replace({
        query: {
          tab: this.tabs[newVal]
        }
      });
    }
  }
};