import TimeFilter from './../statistics/TimeFilter.vue';
import SaleChart from './articleStatisticsCharts/SaleChart.vue';
import SaleChartDetailed from './articleStatisticsCharts/SaleChartDetailed.vue';
import SaleDataPerAdressForSM from './articleStatisticsCharts/SaleDataPerAdressForSM.vue';
import SaleDataPerSM from './articleStatisticsCharts/SaleDataPerSM.vue';
import SaleDataPerAdress from './articleStatisticsCharts/SaleDataPerAdress.vue';
import { mapState } from 'vuex';
export default {
  components: {
    TimeFilter,
    SaleChart,
    SaleChartDetailed,
    SaleDataPerAdressForSM,
    SaleDataPerSM,
    SaleDataPerAdress
  },
  computed: {
    ...mapState({
      showOtherStatistics: state => state.articleStatisticsCharts.showOtherStatistics,
      showDataTables: state => state.articleStatisticsCharts.showDataTables,
      showSaleDataPerAdressForSM: state => state.articleStatisticsDataTable.showSaleDataPerAdressForSM,
      articleInformation: state => state.articleDetails.baseInformation
    })
  },
  watch: {
    async showOtherStatistics(newVal) {
      setTimeout(() => {
        if (newVal) {
          document.getElementById('SaleChart').scrollIntoView({
            behavior: 'smooth'
          });
        }
      }, 250);
    },
    async showSaleDataPerAdressForSM(newVal) {
      setTimeout(() => {
        if (newVal) {
          document.getElementById('TableSaleDataPerAdressForSM').scrollIntoView({
            behavior: 'smooth'
          });
        }
      }, 750);
    }
  },
  destroyed() {
    ;
    this.$store.state.articleStatisticsCharts.SaleChartTotalRevenue = [0, 0, 0, 0], this.$store.state.articleStatisticsCharts.SaleChartPrivateRevenue = [0, 0, 0, 0], this.$store.state.articleStatisticsCharts.SaleChartDetailed = [], this.$store.state.statisticsTimeFilter.fromDate = '', this.$store.state.statisticsTimeFilter.toDate = '', this.$store.state.articleStatisticsCharts.showDataTables = false;
    this.$store.state.articleStatisticsCharts.showOtherStatistics = false;
    this.$store.dispatch('showSaleDataPerAdressForSM', false);
  }
};