import { mapState } from 'vuex';
import currencyFormatService from '@/services/formatters/currencyFormatService';
export default {
  components: {},
  data: () => ({
    search: '',
    characteristic: -1,
    salesPerSM: [],
    headers: [{
      text: 'Sachmerkmal',
      align: 'start',
      sortable: false,
      value: 'characteristic'
    }, {
      text: 'SM1',
      value: 'sm1'
    }, {
      text: 'SM2',
      value: 'sm2'
    }, {
      text: 'SM3',
      value: 'sm3'
    }, {
      text: 'Menge',
      value: 'amount',
      align: 'end'
    }, {
      text: 'Umsatz',
      value: 'revenue',
      align: 'end'
    }]
  }),
  mounted() {},
  computed: {
    ...mapState({
      DataPerSM: state => state.articleStatisticsDataTable.DataPerSM
    })
  },
  methods: {
    openSaleDataPerAdressForSM(value) {
      if (this.characteristic == value.characteristic) {
        this.characteristic = '';
        this.$store.dispatch('showSaleDataPerAdressForSM', false);
      } else {
        this.characteristic = value.characteristic;
        this.$store.dispatch('showSaleDataPerAdressForSM', true);
        this.$store.dispatch('SET_ShowDataPerAdressForSM', value.characteristic);
      }
    },
    itemRowBackground2: function (item) {
      if (item.characteristic == this.characteristic) {
        return 'style-1';
      } else {
        return 'style-2';
      }
    },
    currencyFormatter(value) {
      return currencyFormatService.formatEuro(value);
    }
  }
};