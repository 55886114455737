import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c(VCardText, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "1"
    }
  }), _c(VCol, {
    staticClass: "d-flex align-center justify-center mt-8",
    attrs: {
      "cols": "10"
    }
  }, [_c('TimeFilter')], 1), _c(VCol, {
    attrs: {
      "cols": "1"
    }
  })], 1), _c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center justify-center mt-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Umsatz")])])], 1), _c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('SaleChart', {
    staticStyle: {
      "width": "100%"
    }
  })], 1)], 1), _c('p', {
    staticClass: "pb-5",
    attrs: {
      "id": "SaleChart"
    }
  }), _vm.showOtherStatistics ? _c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center justify-center mt-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Umsatzdiagramm")])])], 1) : _vm._e(), _vm.showOtherStatistics ? _c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('SaleChartDetailed', {
    staticStyle: {
      "width": "100%"
    }
  })], 1)], 1) : _vm._e(), _vm.showOtherStatistics ? _c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center justify-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('SaleDataPerAdress', {
    staticClass: "mt-8",
    staticStyle: {
      "width": "100%"
    }
  })], 1)], 1) : _vm._e(), _vm.showOtherStatistics ? _c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center justify-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('SaleDataPerSM', {
    staticClass: "mt-8",
    staticStyle: {
      "width": "100%"
    }
  })], 1)], 1) : _vm._e(), _c('p', {
    staticClass: "pb-5",
    attrs: {
      "id": "TableSaleDataPerAdressForSM"
    }
  }), _vm.showSaleDataPerAdressForSM ? _c(VRow, [_c(VCol, {
    staticClass: "d-flex align-center justify-center",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('SaleDataPerAdressForSM', {
    staticClass: "mt-8",
    staticStyle: {
      "width": "100%"
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };