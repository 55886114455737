import { mapState } from 'vuex';
import articleFormatService from '@/services/formatters/articleFormatService.js';
export default {
  data() {
    return {
      toBool: false,
      fromBool: false,
      showChartDetailedBool: false,
      // Chart Options
      series: [{
        name: 'Gesamtumsatz',
        data: this.totalRevenue
      }, {
        name: 'Deckungsbeitrag',
        data: this.DBRevenue
      }],
      chartOptions: {
        chart: {
          animations: {
            enabled: true,
            easing: 'linear',
            speed: 500
          },
          zoom: {
            enabled: true
          },
          type: 'bar',
          height: 350,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (this.showOtherStatistics) {
                this.$store.commit('SET_ShowOtherStatistics', false);
                this.$store.dispatch('showSaleDataPerAdressForSM', false);
              } else {
                this.$store.dispatch('SET_ShowStatistics', {
                  timeFilter: this.$store.getters.currentTimeFilter,
                  pointIndex: config.dataPointIndex,
                  artNr: this.$route.params.articleNumber
                });
                this.$store.dispatch('SET_ShowDataPerAdress', {
                  timeFilter: this.$store.getters.currentTimeFilter,
                  pointIndex: config.dataPointIndex,
                  artNr: this.$route.params.articleNumber
                });
                this.$store.dispatch('SET_ShowDataPerSM', {
                  timeFilter: this.$store.getters.currentTimeFilter,
                  pointIndex: config.dataPointIndex,
                  artNr: this.$route.params.articleNumber
                });
                this.$store.commit('SET_DATAPERADRESS').then(this.$store.commit('SET_DATAPERSM'));
              }
            }
          }
        },
        plotOptions: {
          bar: {
            endingShape: 'rounded',
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 10
          }
        },
        legend: {
          labels: {
            useSeriesColors: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['bis Anfangsdatum', 'definierter Zeitraum', 'seit Enddatum', 'Gesamtumsatz']
        },
        yaxis: {
          title: {
            text: ''
          },
          labels: {
            formatter: function (value) {
              return articleFormatService.formatNumber(value);
            }
          }
        },
        fill: {},
        tooltip: {
          y: {
            formatter: function (value) {
              return articleFormatService.formatNumber(value) + '€';
            }
          }
        },
        colors: ['#FF6633', '#3366CC']
      }
    };
  },
  watch: {
    fromDate() {
      this.$store.dispatch('GET_SaleChart', {
        fromDate: this.fromDate,
        toDate: this.toDate,
        artNr: this.$route.params.articleNumber
      });
    },
    toDate() {
      if (!this.toBool) {
        this.toBool = true;
      } else {
        this.$store.dispatch('GET_SaleChart', {
          fromDate: this.fromDate,
          toDate: this.toDate,
          artNr: this.$route.params.articleNumber
        });
      }
    },
    totalRevenue() {
      this.$refs.chart.updateSeries([{
        data: this.totalRevenue
      }, {
        data: this.DBRevenue
      }]);
    }
  },
  computed: {
    ...mapState({
      showOtherStatistics: state => state.articleStatisticsCharts.showOtherStatistics,
      fromDate: state => state.statisticsTimeFilter.fromDate,
      toDate: state => state.statisticsTimeFilter.toDate,
      totalRevenue: state => state.articleStatisticsCharts.SaleChartTotalRevenue,
      DBRevenue: state => state.articleStatisticsCharts.SaleChartDB
    })
  },
  mounted() {
    this.updateSeries();
  },
  methods: {
    async updateSeries() {
      setTimeout(() => {
        this.$refs.chart.updateSeries([{
          data: this.totalRevenue
        }, {
          data: this.privateRevenue
        }, {
          data: this.kkRevenue
        }]);
      }), 1500;
    }
  }
};