import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('h5', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("Umsatz pro Kunde für Sachmerkmal")]), _c(VCard, [_c(VCardTitle, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Search",
      "single-line": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VDataTable, {
    staticClass: "elevation-1 datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.salesPerAdressForSM,
      "search": _vm.search,
      "item-class": _vm.itemRowBackground2
    },
    on: {
      "click:row": _vm.openAdresses
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };